.footer {
    width: 100%;
    max-width: 400px;
    padding: 48px 0;
    display: flex;
    //column-gap: 32px;
    > * {
        margin-right: 32px;
        &:last-child {
            margin-right: 0;
        }
    }
    align-items: center;

    position: absolute;
    bottom: 0;
    margin-left: calc(100vw / 100 * 6.3);
    grid-column: 1 / span 8;

    //margin-left: 6.3%;
    @media (max-width: 1366px) {
        margin-left: calc(100vw / 100 * 6.3);
        grid-column: 1 / span 8;

    }

    @media (max-width: 1024px) {
        position:absolute;
        bottom: -150px;
        left: 0;
        grid-column: 1 / span 12;
        background-color: rgba(0, 0, 0, 0.05);
        max-width: unset;
        padding: 48px 6.3%;
        margin-left: 0;

    }

    @media (max-width: 500px) {
        flex-direction: column;
        //row-gap: 25px;
        > * {
            margin-bottom: 24px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        bottom: -150px;
        padding: 40px ;
    }

    .logo-footer {
        width: 100%;
        max-width: 110px;

        @media (max-width: 500px) {
            max-width: 100px;
        }
    }

    p {
        font-size: responsive 14px 14px;
        font-range: 375px 991px;
        line-height: responsive 14px 14px;
        line-height-range: 375px 991px;
        color: $dark-blue;
    }
}

.footer-bg{
    position: relative;
    background: #f9f9f9;
    width: 100%;
    height: fit-content;


    .footer{
        position: unset;
        padding: 64px 0;
        grid-column: 3 / span 8;
        background: none;

        @media (max-width: 991px) {
            grid-column: 2 / span 6;
        }

        @media (max-width: 374px) {
            grid-column: 1 / span 2;
        }
    }
}
