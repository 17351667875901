//colors
$white: #ffffff;
$black: #000000;
$blue : #007fff;
$light-blue: #99ccff;
$dark-blue: #004a95;
$green: #7acb54;
$red: #e11b3f;



//fonts
@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400;500;600;700;800&display=swap');