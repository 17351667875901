.header{
    width: 100%;
    padding: 1.1% 6.3%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: 100px;

    @media (max-width: 991px) {
       padding: 20px 25px;
    }

    .header-logo{
        cursor: pointer;
        width: 100%;
        max-width: 110.2px;
        @media (max-width: 500px) {
            max-width: 90px;
        }
    }

    .main-btn{
        font-size: responsive 14px 18px;
        font-range: 375px 991px;
        line-height: responsive 14px 18px;
        line-height-range: 375px 991px;
    }

    .menu{
        width: 100%;
        max-width: 762px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        //column-gap: 40px;
        //> * {
        //    margin-right: 40px;
        //    &:last-child {
        //        margin-right: 0;
        //    }
        //}
        align-items: center;

        @media (max-width: 1200px) {
            flex-direction: row;
            justify-content: flex-end;
        }

        @media (max-width: 500px) {
           //column-gap: 24px;
           // > * {
           //     margin-right: 24px;
           //     &:last-child {
           //         margin-right: 0;
           //     }
           // }
        }

        .links{
            display: flex;
            flex-direction: row;
            //column-gap: 40px;
            //> * {
            //    margin-right: 40px;
            //    &:last-child {
            //        margin-right: 0;
            //    }
            //}

            @media (max-width: 1200px) {
               display: none;
               flex-direction: column;
               //row-gap: 56px;
                > * {
                    margin-bottom: 32px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
               align-items: flex-end;
               background: $white;
               border-top: rgba(0, 0, 0, 0.4) solid;
               position: absolute;
               right: 0;
               width: 30%;
               top: 100%;
               height: 100vh;
               padding: 32px 6.3%;
               z-index: 9;
               transition: all .3s ease-in-out;

            }

            @media (max-width: 991px) {
                width: 70%;
            }

            @media (max-width: 500px) {
                width: 100%;
                max-width: 270px;
            }


            &.active{
                display: flex;
                transition: all .3s ease-in-out;
            }

            .menu-link{

                position: relative;
                z-index: 999;
                text-align: center;
                cursor: pointer;

                @media (min-width:1201px) {
                    margin-right: 32px;
                }
                @media (max-width:1200px) {
                    text-align: right;
                }

                a{
                    font-size: responsive 16px 16px;
                    font-range: 375px 991px;
                    font-weight: 600;
                    text-decoration: none;
                    padding: 0 6px;
                    transition: all .3s ease-in-out;

                    &:before{
                        content:"";
                        margin: 0 auto;
                        position: absolute;
                        top: 45%;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        width: 100%;
                        z-index: -1;
                        cursor: pointer;

                        @media (max-width: 1200px) {
                            display: none;
                        }
                    }

                    &:hover::before, &:active::before, &:focus::before{
                        border-top: 4px solid $light-blue;
                    }

                    &:hover, &:active, &:focus{
                        @media (max-width: 1200px) {
                            border-bottom: $light-blue solid 4px;
                            transition: all .3s ease-in-out;
                        }
                    }
                }
            }
        }
    }

}
