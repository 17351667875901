
.container.alert{
    position: relative;
}

.terms {
    position: fixed !important;
    left: 50% !important;
    transform: translate(-50%, 0) !important;
}

.alert-bg {
    height: 100vh;
    position: absolute;
    background: rgba(0,0,0,0.85);
    width: 100vw;
    z-index: 1999;
}
.alert-container{

    display: flex; //when visible display:flex
    flex-direction: column;
    //row-gap: 8px;
    > * {
        margin-bottom: 8px;
        &:last-child {
            margin-bottom: 0;
        }
    }

    position: absolute;
    top: 50px;
    z-index: 2999;
    grid-column: 4 / span 6;
    background: $white;

    width: 100%;
    max-width: 620px;
    max-height: 532px;

    padding: 40px;
    //left: 50%;
    //transform: translateX(-50%);

    @media (max-width: 1200px) {
       max-height: unset;
    }

    @media (max-width: 991px) {
        grid-column: 2 / span 6;
    }

    @media (max-width: 768px) {
        padding: 24px;
    }

    @media (max-width: 374px) {
        grid-column: 1 / span 2;
    }

    .icon-close{
        max-width: 32px;
        align-self: flex-end;
        margin-bottom: 8px;
        cursor:pointer;

        @media (max-width: 768px) {
            max-width: 24px;
        }
    }

    h3{
        font-size: responsive 22px 24px;
        font-range: 375px 991px;
        line-height: responsive 27.5px 30px;
        line-height-range: 375px 991px;
        margin-bottom: 8px;
    }

    p{
        font-size: responsive 16px 18px;
        font-range: 375px 991px;
        line-height: responsive 24px 27px;
        line-height-range: 375px 991px;
    }

    .user-profile{
        padding-left: 0;

        .user-picture{
           width: 100%;
        }
    }

    hr{
        margin: 16px 0;
    }

    .information-text{
        font-size: responsive 14px 14px;
        font-range: 375px 991px;
        line-height: responsive 21px 21px;
        line-height-range: 375px 991px;
        margin: 24px 0 8px 0;

        @media (max-width: 768px) {
           margin: 16px 0 0 0;
           padding: 0;
        }
    }

    .main-btn{
        width: 100%;
        max-width: unset;
    }

    &.alert{
        //row-gap: 16px;
        > * {
            margin-bottom: 16px;
            &:last-child {
                margin-bottom: 0;
            }
        }

        @media (max-width: 768px) {
            //row-gap: 8px;
            > * {
                margin-bottom: 8px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .icon-check{
            max-width: 60px;

            @media (max-width: 768px) {
                margin-bottom: 8px;
            }
        }

        h3{
            margin-bottom: 0;
        }

        .main-btn{
            margin-top: 16px;
        }
    }

    &.delete-alert{
        max-height: unset;

        .text-alert{
            span{
                color: $red;
                font-weight: 700;
                font-size: responsive 16px 22px;
                font-range: 375px 991px;
                line-height: responsive 24px 33px;
                line-height-range: 375px 991px;
            }
        }

        .account-selection{
            margin-top: 5%;
            display: flex;
            flex-direction: column;
            //row-gap: 16px;
            > * {
                margin-bottom: 16px;
                &:last-child {
                    margin-bottom: 0;
                }
            }

            .input-box{
                display: flex;
                //column-gap: 16px;
                > * {
                    margin-right: 16px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
                align-items: center;
                position: relative;

                input[type=checkbox] {
                    -webkit-appearance: none;
                    border: solid 2px rgba(0, 0, 0, 0.5);
                    background-color: transparent;
                    width: 32px;
                    height: 32px;
                    padding: 5px;

                    display: inline-block;
                    cursor: pointer;

                }

                .check{
                    position: absolute;
                    z-index: -1;
                    cursor: pointer;
                }

                input[type=checkbox]:checked + .check{
                    border: solid 2px rgba(0, 0, 0, 0.5);
                    position: absolute;
                    left: 5px;
                    background: $green;
                    width: 22px;
                    height: 22px;
                }

                label{
                    text-transform: uppercase;
                    font-size: 18px;
                    line-height: 18px;
                    font-weight: 600;

                }

                &.error{
                    input[type=checkbox] {
                        border: solid 2px $red;
                    }

                    label{
                        color: $red;
                    }

                }
            }

            .error-text{
                display: flex; //when visisble display: flex
                //column-gap: 8px;
                > * {
                    margin-right: 8px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
                margin-top: 8px;

                &.hidden {display: none}

                .icon-alert{
                    width: 100%;
                    max-width: 24px;
                    height: 24px;
                }

                p{
                    color: $red;
                    font-size: 14px;
                    line-height: 21px;
                }
            }

            .main-btn.red{
                background: $red;
                margin-top: 16px;

                @media (max-width: 768px) {
                    margin-top: 8px;
                }
            }

        }
    }
}
.container-wrapper {
    height: 100vh;
    width: 100vw;
    background: transparent;
    position: fixed;
    overflow: hidden;
    z-index: 20000;
    .alert-bg {
        position: fixed !important;
        overflow: hidden !important;
        top: 0;
        bottom: 0;
    }
    .container.alert{
        overflow: scroll;
        padding: 50px 0;

        &::-webkit-scrollbar {
            display: none;
        }

        & {
            -ms-overflow-style: none;
            scrollbar-width: none;
        }

        .alert-container{
            top: 0;
            position: initial;
        }
    }
}
