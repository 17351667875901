.gest-privacy {

    .left-content {
        grid-column: 1 / span 4;
        @media (max-width: 1199px) {
            grid-column: 1 / span 5;
        }
        flex-direction: column;
        align-items: flex-start;
        justify-content: start;
        //row-gap: 24px;
        > * {
            margin-bottom: 24px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        @media (max-width: 1199px) {
            width: 100%;
        }
        padding-top: 64px;

        @media (max-width: 1366px) {
            padding: 64px 20px;
        }

        @media (max-width: 1024px) {
            grid-row: 1;
            grid-column: 1 / span 12;
            padding: 64px 20px;
        }

        @media (max-width: 991px) {
            grid-column: 1 / span 8;
            padding: 56px 20px;
        }

        @media (max-width: 374px) {
            grid-column: 1 / span 2;
            padding: 32px 20px;
        }

        p {
            padding-right: 5%;
            font-size: responsive 16px 18px;
            font-range: 375px 991px;
            line-height: responsive 24px 27px;
            line-height-range: 375px 991px;

            span {
                text-transform: uppercase;
                font-weight: 700;
            }
        }

        .mobile-btn{
            display: none;

            @media (max-width: 1024px) {
                display: block;
                max-width: unset;
                text-transform: uppercase;
                font-size: 16px;
            }

        }

        .cancellation-box {
            background-color: $red;
            padding: 24px;
            max-width: 486px;
            width: 100%;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            //row-gap: 16px;
            > * {
                margin-bottom: 16px;
                &:last-child {
                    margin-bottom: 0;
                }
            }

            @media (max-width: 1024px) {
               max-width: unset;
                width: 100%;
            }

            p {
                color: $white;

                span {
                    color: $white;
                }
            }

            .cancel-acc-cta {
                width: 100%;
                height: 50px;

                background-color: $white;
                border: none;
                outline: none;
                cursor: pointer;

                color: $red;
                text-transform: uppercase;
                font-weight: 700;
                font-size: responsive 16px 18px;
                font-range: 375px 991px;
                line-height: responsive 24px 18px;
                line-height-range: 375px 991px;
                transition: all .3s ease-in-out;

                &:hover,
                &:active,
                &:focus {
                    background: #a0132d;
                    color: $white;
                    transition: all .3s ease-in-out;
                }
            }
        }
    }

    .right-content {
        grid-column: 5 / span 7;
        @media (max-width: 1199px) {
            grid-column: 6 / span 7;
        }
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        //row-gap: 40px;
        > * {
            margin-bottom: 40px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        width: 100%;
        max-width: 734px;
        min-height: calc(100vh - 85.83px);

        padding: 56px 0 7%;


        @media (max-width: 1200px) {
            grid-column: 6/ span 6;
        }

        @media (max-width: 1024px) {
           grid-row: 2;
           grid-column: 1 / span 12;
           max-width: unset;
           padding: 100px 20px;
        }

        @media (max-width: 991px) {
            grid-column: 1 / span 8;
            padding: 56px 20px;
        }


        @media (max-width: 374px) {
            grid-column: 1 / span 2;
            padding: 40px 20px;
        }

        .settings-section {
            display: flex;
            flex-direction: column;
            //row-gap: 16px;
            > * {
                margin-bottom: 16px;
                &:last-child {
                    margin-bottom: 0;
                }
            }

            width: 100%;
            position: relative;

            @media (max-width: 500px) {
                //row-gap: 24px;
                > * {
                    margin-bottom: 24px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .description-text {
                padding-left: 40px;

                @media (max-width: 1024px) {
                   padding-left: 0;
                }

                p {
                    margin-bottom: 16px;
                    padding-right: 17%;

                    @media (max-width: 500px) {
                        padding-right: 5%;
                    }
                }
            }
            .spin-brands {
                min-height: 400px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .brands-section {
                margin-left: 40px;
                width: calc(100% - 40px);
                //padding-right: 40%;
                //width: 100%;

                .ps__rail-x {
                    opacity: 0.6;
                    background-color: transparent !important;
                    .ps__thumb-x {
                        bottom: 0;
                        opacity: 0.6;
                    }
                    &:hover, &:active {
                        .ps__thumb-x {
                            height: 8px !important;

                        }
                    }
                }

                overflow-x: auto;

                display: flex;
                //column-gap: 24px;
                > * {
                    margin-right: 24px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
                align-items: baseline;
                height: 40px;

                @media (max-width: 1024px) {
                    width: 100%;
                    margin-left: 0;
                    padding-left: 0;
                    background-color: white;
                    height: 60px;
                }

                @media (max-width: 991px) {
                    padding-right: 14.5%;
                }

                @media (max-width: 768px) {
                    padding-right: 10%;
                }

                @media (max-width: 500px) {
                    //min-width: 525px;
                    //margin-left: -25px;
                    //padding-left: 25px;
                    //margin-right: -25px;
                    //padding-right: 25px;
                    //padding-right: 50%;
                }

                .brand-cta.brand{
                    min-width: fit-content;
                }
                .brand-cta.brand.active{

                }

                //&::-webkit-scrollbar {
                //    display: block;
                //    height: 1px;
                //}

            }
            .desk-tabbar {
                padding-left: 40px;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                @media (max-width: 1199px) {
                    display: none;
                }
                .brand-cta {
                    margin-right: 60px;
                    margin-bottom: 10px;
                }
            }

            .mobile-tabbar {
                @media (min-width: 1200px) {
                    display: none;
                }
            }
        }
    }
}
