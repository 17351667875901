* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

//variabili e in comune
@import 'variables';
@import 'commons';

//normalize
@import "normalize";
h1, h2, h3, h4 {
    margin-top: 0;
}
//components
@import './components/header';
@import './components/buttons';
@import './components/footer';
@import './components/user-accounts';
@import './components/settings';
@import './components/alert';

//pagine
@import 'home';
@import 'gest-privacy';
@import 'informations';
