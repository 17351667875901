.home {

    .container-bg {
        min-height: calc(100vh - 100px);

        @media (max-width: 1024px) {
            height: 100%;
            max-height: unset;
        }

        @media (max-height: 768px) {
            height: 100%;
            max-height: unset;
        }
    }
}

.left-content {
    grid-column: 1 / span 8;
    display: flex;
    align-items: center;
    background: #f9f9f9;

    @media (max-width: 1366px) {
        grid-row: 1;
        grid-column: 1 / span 6;
        align-content: start;
    }

    @media (max-width: 1024px) {
        grid-column: 1 / span 12;
        grid-row: 1;
    }

    @media (max-width: 991px) {
        grid-column: 1 / span 8;

    }

    @media (max-width: 374px) {
        grid-column: 1 / span 2;
    }


    .out-grid-text {
        width: 100%;
        max-width: 825px;
        display: flex;
        flex-direction: column;
        //row-gap: 32px;
        > * {
            margin-bottom: 32px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        margin: -10% 0 0 -23%;
        background-color: #f9f9f9;

        @media (min-width: 2300px) {
            margin: 30% 0 30% -55%;
        }

        @media (max-width: 1800px) {
            margin-left: -15%;
        }

        @media (max-width: 1600px) {
            margin-left: -7%;
        }

        @media (max-width: 1500px) {
            margin: 5% 0;
        }

        @media (max-width: 1366px) {
            margin: 2% 10%;
        }

        @media (max-width: 1024px) {
            max-width: unset;
            margin: 5% 6.3%;
            height: unset;
        }

        @media (max-width: 768px) {
            margin: 10% 6.3%;
        }

        @media (max-width: 500px) {
            margin: 32px 6.3%;
            //row-gap: 8px;
            > * {
                margin-bottom: 8px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }


        p.welcome-text {
            font-size: responsive 14px 20px;
            font-range: 375px 991px;
            line-height: responsive 21px 30px;
            line-height-range: 375px 991px;

            @media (max-width: 374px) {
                margin-bottom: 8px;
            }
        }
    }
}

.right-content {
    grid-column: 9 / span 4;
    background-color: white;
    box-shadow: -15px 0 15px -9px rgba(0, 0, 0, 0.1);
    padding: 50px 0 50px 50px;

    display: flex;
    align-items: center;

    @media (max-width: 1366px) {
        grid-row: 1;
        grid-column: 7 / span 6;
    }

    @media (max-width: 1024px) {
        grid-column: 1 / span 12;
        grid-row: 2;
        padding: 50px 6.3%;
        box-shadow: unset;
        height: unset;

    }

    @media (max-height: 768px) {
        height: auto;
        overflow: initial;
        //padding-top: 20%;
        //padding-bottom: 5%;

        &::-webkit-scrollbar{
            display: none;
        }
    }

    @media (max-width: 991px) {
        grid-column: 1 / span 8;
    }

    @media (max-width: 374px) {
        grid-column: 1 / span 2;
        margin: 0;
    }

    h2 {
        text-transform: uppercase;
        margin-bottom: 8px;
        font-weight: bold;
    }

    p {
        margin-bottom: 24px;
        padding-right: 10%;
    }

    .spin {
        background: #004a95;
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;
        width: 100%;
        justify-content: center;
        height: 50px;
    }

    .re-send {
        margin-top: 16px;
    }

    //form-section
    .form-section {
        display: block; //whien visible display: block
        width: 100%;
        max-width: 312px;

        @media (max-width: 1024px) {
            max-width: unset;
        }

        .divider {
            margin: 40px 0;

            @media (max-width: 500px) {
                margin: 32px 0;
            }

            p {
                margin: 0;
                padding: 0;
                display: table;
                color: rgba(0, 0, 0, 0.5);

                &:after {
                    border-top: 1px solid rgba(0, 0, 0, 0.5);
                    content: '';
                    display: table-cell;
                    position: relative;
                    top: 0.8em;
                    left: 10px;
                    width: 83.5%;

                    @media (max-width: 1024px) {
                        width: 94.1%;
                    }

                    @media (max-width: 374px) {
                        width: 83.3%;
                    }
                }
            }
        }

        //form
        .form {
            display: flex;
            flex-direction: column;
            //row-gap: 16px;
            > * {
                margin-bottom: 16px;
                &:last-child {
                    margin-bottom: 0;
                }
            }

            .error-txt {
                display: none;
            }

            input[type=text],
            input[type=email],
            input[type=password] {
                width: 100%;
                padding: 16px;
                border: solid 1px rgba(0, 0, 0, 0.2);
                font-size: 16px;
                outline: none;
                transition: all .3s ease-in-out;
                border-radius: 0 !important;
                appearance: none;

                &:hover,
                &:active,
                &:focus {
                    border: solid 1px $dark-blue;
                    outline: none;
                    transition: all .3s ease-in-out;
                }
            }

            ::placeholder {
                font-family: 'Syne', sans-serif;
                color: rgba(0, 0, 0, 0.5);
                font-weight: 600;
                font-size: 16px;
            }

            .input-psw {
                display: flex;
                flex-direction: row;
                align-items: center;
                position: relative;
                border: none;


                .icon-show-psw {
                    max-width: 24px;
                    height: 24px;
                    position: absolute;
                    right: 16px;
                    cursor: pointer;
                    transition: all .3s ease-in-out;

                    &.active {
                        transition: all .3s ease-in-out;
                        filter: invert(34%) sepia(47%) saturate(5369%) hue-rotate(199deg) brightness(104%) contrast(103%);
                    }

                    &:hover,
                    &:active,
                    &:focus {
                        transition: all .3s ease-in-out;
                        transform: scale(1.1);
                    }
                }
            }

            //&.error {
                .error-txt-active {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    //column-gap: 8px;
                    > * {
                        margin-right: 8px;
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                    color: $red;
                    margin-top: -7px;

                    .icon-alert {
                        max-width: 24px;
                        height: 24px;
                    }
                }

                .error-input-active {
                    border: solid 1px $red !important;
                    color: $red;

                    &:hover,
                    &:active,
                    &:focus {
                        border: solid 1px $dark-blue;
                        outline: none;
                        transition: all .3s ease-in-out;
                    }
                    ::placeholder {
                        color: $red;
                    }
                }
            //}
        }
    }

    //confirmation
    .confirmation {
        display: none; //when visible display: flex, and add class confirmation-bg to container-bg
        width: 100%;
        max-width: 312px;
        flex-direction: column;

        @media (max-width: 1024px) {
            max-width: unset;
        }

        .icon-check {
            width: 100%;
            max-width: 60px;
            margin-bottom: 24px;
        }

        p {
            padding-right: 3%;
            font-weight: 500;
            margin: 0;

            span {
                @media (max-width: 1024px) {
                    display: none;
                }
            }
        }

        hr {
            margin: 32px 0;
        }

        .main-btn {
            margin-top: 16px;
        }
    }

}
.home {
    .main-btn {
        @media(max-width: 768px) {
            min-width: 100%;
            max-width: 100%;
        }
    }
}
