.user-profile {
    width: 100%;
    display: flex;
    flex-direction: row;
    //column-gap: 24px;
    > * {
        margin-right: 24px;
        &:last-child {
            margin-right: 0;
        }
    }
    padding-left: 40px;
    position: relative;

    @media (max-width: 1024px) {
        padding-left: 0;
    }

    @media (max-width: 500px) {
       //column-gap: 16px;
        > * {
            margin-right: 16px;
            &:last-child {
                margin-right: 0;
            }
        }
    }

    .user-picture {
        max-width: 60px;
        height: 60px;

        @media (max-width: 500px) {
            max-width: 40px;
            height: 40px;
        }
    }

    .user-info {
        padding: 8.5px 0;

        @media (max-width: 500px) {
            padding: 0;
        }

        p {
            margin: 0;
        }

        .email {
            font-size: responsive 16px 18px;
            font-range: 375px 991px;
            line-height: responsive 20px 22.5px;
            line-height-range: 375px 991px;
            transition: all .3s ease-in-out;
        }


        .name {
            opacity: 0.5;
            font-size: responsive 14px 16px;
            font-range: 375px 991px;
            line-height: responsive 17.5px 20px;
            line-height-range: 375px 991px;
            transition: all .3s ease-in-out;
        }
    }

    .icon-chevron {
        margin-left: 8px;
        max-width: 24px;
        height: 24px;
        cursor: pointer;
        transform: rotate(180deg);
        margin-top: -3%;
        align-self: center;

        @media (max-width: 500px) {
           align-self: center;
           max-width: 20px;
           height: 20px;
           margin-left: 0;
        }

        &.active {
            transform: unset;
        }
    }


    //accounts-box

    .accounts-box {
        display: none;
        flex-direction: column;
        //row-gap: 16px;
        > * {
            margin-bottom: 16px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        align-items: center;

        width: 100%;
        max-width: 356px;

        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        border: solid 1px rgba(0, 0, 0, 0.2);
        background-color: #fff;

        position: absolute;
        top: 125%;
        z-index: 9999;

        @media (max-width: 500px) {
            //row-gap: 0;
            > * {
                margin-bottom: 0;
            }
        }

        .accounts {
            padding: 24px;
            padding-bottom: 0;
            width: 100%;
            display: flex;
            flex-direction: column;
            //row-gap: 16px;
            > * {
                margin-bottom: 16px;
                &:last-child {
                    margin-bottom: 0;
                }
            }

            @media (max-width: 500px) {
               padding: 16px;
            }

            .account-profile {
                display: flex;
                flex-direction: row;
                //column-gap: 16px;
                > * {
                    margin-right: 16px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
                cursor: pointer;

                .user-picture {
                    max-width: 40px;
                    height: 40px;
                }

                .account-info {
                    padding: 0.5px 0;

                    @media (max-width: 500px) {
                        overflow: hidden;
                    }

                    p {
                        margin: 0;
                    }

                    .email {
                        font-size: responsive 16px 16px;
                        font-range: 375px 991px;
                        line-height: responsive 20px 20px;
                        line-height-range: 375px 991px;
                        transition: all .3s ease-in-out;
                    }


                    .account-type {
                        opacity: 0.5;
                        font-size: responsive 14px 14px;
                        font-range: 375px 991px;
                        line-height: responsive 17.5px 17.5px;
                        line-height-range: 375px 991px;
                        transition: all .3s ease-in-out;
                    }
                }

            }

            .add-account{
                background: none;
                border: none;
                display: flex;
                align-items: center;
                //column-gap: 16px;
                > * {
                    margin-right: 16px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
                cursor: pointer;

                font-size: responsive 16px 16px;
                font-range: 375px 991px;
                line-height: responsive 24px 20px;
                line-height-range: 375px 991px;

                .icon-add-account{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    max-width: 40px;
                    height: 40px;
                    border: solid 1px rgba(0, 0, 0, 0.5);
                    border-radius: 100%;

                    img{
                        width: 24px;
                        height: 24px;
                    }
                }



            }
        }

        &.active{
            display: flex;
        }
    }
}
