.settings {
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    flex-direction: column;
    //row-gap: 24px;
    > * {
        margin-bottom: 24px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media (max-width: 1024px) {
        padding-left: 0;
        padding-right: 0;
    }

    .setting-item {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 500px) {
            flex-direction: column;
            align-items: flex-start;
            //row-gap: 20px;
            > * {
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        p {
            width: 100%;
            max-width: 471px;
            padding-right: 5%;
            margin-bottom: 0;

            @media (max-width: 500px) {
                margin-bottom: 20px;
                padding-right: 0;

                br {
                    display: none;
                }
            }
        }

        .toggle-section {
            width: 100%;
            max-width: 175px;
            display: flex;
            flex-direction: row;
            align-items: center;
            //column-gap: 16px;
            > * {
                margin-right: 16px;

                &:last-child {
                    margin-right: 0;
                }
            }

            font-weight: 700;
            font-size: responsive 16px 16px;
            font-range: 375px 991px;
            line-height: responsive 24px 24px;
            line-height-range: 375px 991px;

        }
    }


    .main-btn {
        margin-top: 32px;

        @media (max-width: 1024px) {
            max-width: unset;
        }

        @media (max-width: 768px) {
            margin: 0;
            font-size: 20px;
            text-transform: uppercase;
            position: fixed;
            bottom: 0;
            left: 0;
            z-index: 9;
        }
    }
}
