//menu-hamburger-button
.hamb-btn {
    display: none;

    @media (max-width: 1200px) {
        display: block;
        width: 40px;
        height: 40px;
        cursor: pointer;
        background: url("../../img/icon-hamb.svg") no-repeat;
        background-size: contain;
        background-position: center;
        border: none;
        transition: all .3s ease-in-out;
        margin-left: 32px;
    }

    &.active {
        background: url("../../img/icon-close-menu.svg") no-repeat;
        background-size: contain;
        background-position: center;
        transition: all .3s ease-in-out;
    }
}

//buttons
.header-btn {
    width: 100%;
    max-width: 94px;
    height: 40px;

    background: $blue;
    border: none;
    cursor: pointer;
    text-align: center;

    transition: all .3s ease-in-out;

    @media (max-width: 500px) {
        max-width: 86px;
        height: 32px;
    }

    span {
        color: $white;
        font-size: responsive 14px 16px;
        font-range: 375px 991px;
        line-height: responsive 14px 16px;
        line-height-range: 375px 991px;
        font-weight: 700;
    }

    &:hover,
    &:active,
    &:focus {
        background: $dark-blue;
        transition: all .3s ease-in-out;
    }
}
.brand-button {
    width: 100%;
    max-width: 312px;
    height: 50px;
    display: flex;
    background: $blue;
    cursor: pointer;
    text-align: center;

    color: $white;
    font-size: responsive 18px 18px;
    font-range: 375px 991px;
    line-height: responsive 18px 18px;
    line-height-range: 375px 991px;
    font-weight: 700;

    align-items: center;
    justify-content: center;
    text-decoration: none;
    @media(max-width: 767px) {
        max-width: unset;
    }
}

.main-btn {

    width: 100%;
    max-width: 312px;
    height: 50px;

    background: $blue;
    border: none;
    cursor: pointer;
    text-align: center;

    transition: all .3s ease-in-out;

    color: $white;
    font-size: responsive 18px 18px;
    font-range: 375px 991px;
    line-height: responsive 18px 18px;
    line-height-range: 375px 991px;
    font-weight: 700;
    border-radius: 0;

    &.disabled {
        display: none;
    }

    &.green {
        background: $green;
        display: none;

        &.active {
            display: block;
        }
    }

    &:hover,
    &:active,
    &:focus {
        background: $dark-blue;
        transition: all .3s ease-in-out;
    }
    &.center-spinner {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}


.brand-cta {
    width: fit-content;


    text-transform: uppercase;
    font-size: responsive 18px 18px;
    font-range: 375px 991px;
    line-height: responsive 18px 18px;
    line-height-range: 375px 991px;
    font-weight: 700;
    color: $blue;

    background: none;
    border: solid 1px $white;


    padding-bottom: 8px;
    cursor: pointer;

    @media (max-width: 1024px) {
        padding: 12px 16px;
        border: solid 1px $blue;
    }

    &:hover,
    &:active,
    &:focus,
    &.active {
        color: $dark-blue;
        border-bottom: 1px solid $dark-blue;

        @media (max-width: 1024px) {
            border: solid 1px $dark-blue;
            background: $dark-blue;
            color: $white;
        }
    }
}

.logout {
    width: 100%;
    padding: 12px 0;

    border: none;
    background: $red;
    color: $white;

    font-size: responsive 14px 16px;
    font-range: 375px 991px;
    line-height: responsive 14px 16px;
    line-height-range: 375px 991px;
    font-weight: 600;

    cursor: pointer;
    transition: all .3s ease-in-out;

    &:hover,
    &:active,
    &:focus {
        background: #a0132d;
        transition: all .3s ease-in-out;
    }
}

//switch-toggle
.toggle-switch {
    width: 100%;
    max-width: 96px;
    height: 32px;
    border-radius: 16px;
    padding: 3px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    background: rgba(0, 0, 0, 0.5);
    border-radius: 30px;

    opacity: 0.3;
    transition: all .5s ease-in-out;

    position: relative;

    &.active {
        opacity: 1;
        transition: all .5s ease-in-out;
    }

    .input-cont {

        width: 100%;
        max-width: 18px;
        height: 12px;

        position: relative;

        input {
            display: none;
        }

        label {
            cursor: pointer;

            &.radio_label {
                float: left;
                width: 18px;
                height: 18px;
                position: absolute;
                bottom: -2.5px;
                z-index: 9;
            }
        }
    }

    //.icon-face-l {
    //    margin: 7px 0 7px 9px;
    //    background: url('../../img/icon-neutral.svg') no-repeat;
    //}
    //
    //.icon-face-r {
    //    margin: 7px 9px 7px 0;
    //    background: url('../../img/icon-neutral.svg') no-repeat;
    //}


    .slider {
        display: block;
        position: absolute;
        left: 34px;
        right: 34px;
        width: 26px;
        height: 26px;
        background: $white;
        border-radius: 100%;
        cursor: pointer;
        transition: all .5s ease-in-out;
    }

}

.toggle-switch.off{
    background-color: $red !important;
    transition: all .5s ease-in-out;

    //.icon-face-l {
    //    background: none;
    //}
    //
    //.icon-face-r {
    //    background: url('../../img/icon-sad.svg') no-repeat;
    //}

    .slider{
        left: 3px;
        transition: all .5s ease-in-out;
    }
}

.toggle-switch.on{
    background-color: $green !important;
    transition: all .5s ease-in-out;

    //.icon-face-l {
    //    background: url('../../img/icon-happy.svg') no-repeat;
    //}
    //
    //.icon-face-r {
    //    background: none;
    //}

    .slider{
        right: 3px;
        left: initial;
        transition: all .5s ease-in-out;
    }
}

