.top-section{
    background: #f9f9f9;

    .title-text{
        grid-column: 3 / span 8;
        margin: 64px 0;
        display: flex;
        flex-direction: column;
        //row-gap: 16px;
        > * {
            margin-bottom: 16px;
            &:last-child {
                margin-bottom: 0;
            }
        }

        @media (max-width: 991px) {
            grid-column: 2 / span 6;
        }
        @media (max-width: 374px) {
            grid-column: 1 / span 2;
        }

        h1{
            text-transform: uppercase;
        }

        .subtitle{
            text-transform: none;
        }

        p{
            font-size: responsive 14px 18px;
            font-range: 375px 991px;
            line-height: responsive 21px 27px;
            line-height-range: 375px 991px;

            a{
                color: $blue;
                font-weight: 600;
            }
        }
    }
}

.content{
    grid-column: 3 / span 8;
    padding: 64px 0;

    display: flex;
    flex-direction: column;
    //row-gap: 64px;
    > * {
        margin-bottom: 64px;
        &:last-child {
            margin-bottom: 0;
        }
    }

    @media (max-width: 991px) {
        grid-column: 2 / span 6;
    }

    @media (max-width: 374px) {
        grid-column: 1 / span 2;
    }

    .description{
        margin-top: 16px;
        font-size: responsive 14px 18px;
        font-range: 375px 991px;
        line-height: responsive 21px 27px;
        line-height-range: 375px 991px;

        a{
            font-weight: 600;
            color: $blue;
        }

        ul{
            list-style-type: square;
            padding-left: 4%;
        }
        ol{
            counter-reset: list;
        }
        ol > li {
            list-style: none;
            width: 100%;
            max-width: 800px;
            position: relative;
            left: 2rem;
        }
        ol > li:before {
            counter-increment: list;
            content: counter(list, lower-alpha) ") ";
            position: absolute;
            left: -2rem;
        }

        .sub-description{
            padding-left: 40px;

            @media (max-width: 500px) {
                padding-left: 0;
            }
        }
    }

}

.informativa-separator {
    height: 1px;
    background-color: rgba(0,0,0,0.2);
    @media(max-width: 991px) {
        display: none;
    }
}
.informations {
    .content {
        @media(max-width: 768px) {
            //> * {
            //    margin-bottom: 32px;
            //}
        }
    }
}
.informative-box-container {
    display: flex;
    justify-content: space-between;
    //gap: 32px;
    > * {
        margin-bottom: 32px;
        padding-right: 10px;
        flex: 1;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .informativa-box {
        //margin-bottom: 64px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    //max-width: calc(100% - 32px);

    @media (max-width: 991px) {
        display: block;
        margin-bottom: 0px;

        .informativa-box {
            margin-bottom: 64px !important;
        }
    }

    h2 {
        font-size: responsive 23px 32px;
        font-range: 375px 1920px;
        line-height: responsive 30px 48px;
        line-height-range: 375px 991px;
    }
    a {
        &.lines {
            background-color: #57346f;
        }
        &.ace {
            background-color: #82bce8;
        }
        &.specialist {
            background-color: #52d1cc;
        }
        &.baby {
            background-color: #ffc300;
        }
        &.pampers {
            background-color: #04b2aa;
        }
        &.tampax {
            background-color: #004a95;
        }
        &.serv {
            background-color: #e11b3f;
        }
    }
}
