*, body{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Syne', sans-serif;
    color: $black;
}
html,body{
    height: 100%;
    overflow-x: hidden;
}

.container-bg{
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    width: 100%;
    position: relative;

    .left-bg{
        grid-row: 1;
        grid-column: 1 / span 8;
        width: 100%;
        height: 100%;
        background-color: #f9f9f9;

        @media (max-width: 1024px) {
            grid-row: 1;
            grid-column: 1 / span 12;
        }
    }

    .right-bg{
        grid-row: 1;
        grid-column: 9 / span 12;
        width: 100%;
        height: 100%;
        background-color: #fff;

        @media (max-width: 1024px) {
            grid-row: 2;
            grid-column: 1 / span 12;
        }
    }
}

.container{
    grid-row: 1;
    grid-column: 1 / span 12;
    width: 100%;
    max-width: 1280px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    //column-gap: 30px;
    > * {
        margin-right: 30px;
        &:last-child {
            margin-right: 0;
        }
    }
    margin: 0 auto;
    height: 100%;

    @media (max-width: 1024px) {
        grid-row: 1 / span 2;
        grid-column: 1 / span 12;
    }

    @media (max-width: 991px) {
        grid-template-columns: repeat(8, 1fr);
        width: 100%;
    }

    @media (max-width: 374px) {
        grid-template-columns: repeat(2, 1fr);

    }
}

.overlay-menu{
    @media (max-width:1200px){
        display: none;
        width: 100%;
        height: 100vh;
        background: $black;
        opacity: 0.8;
        transition: all .3s ease-in-out;
        position: absolute;
        z-index: 2;
    }
}

.overlay-fullpage{
    display: none;
    width: 100%;
    height: 100vh;
    background: $black;
    opacity: 0.9;
    transition: all .3s ease-in-out;
    position: absolute;
    z-index: 98;
}

//fonts
h1{
    font-size: responsive 22px 32px;
    font-range: 375px 991px;
    line-height: responsive 27.5px 40px;
    line-height-range: 375px 991px;
}

h2{
    font-size: responsive 24px 24px;
    font-range: 375px 991px;
    line-height: responsive 30px 30px;
    line-height-range: 375px 991px;
}

p{
    font-size: responsive 16px 16px;
    font-range: 375px 991px;
    line-height: responsive 24px 24px;
    line-height-range: 375px 991px;
}

.rai-spinner {
    &.white{
        .rai-spinner-outer {
            border: 1px solid white;
        }
        .rai-spinner-inner {
            border: 2px solid white;
        }
    }
}
